<script setup lang="ts">
const props = defineProps<{
  tradeshow: Tradeshow
}>()

const startDate = useDateFormat(props.tradeshow.start_date, 'MMMM D, YYYY')
const startTime = useDateFormat(props.tradeshow.start_date, 'h:mm a')

const endDate = useDateFormat(props.tradeshow.end_date, 'MMMM D, YYYY')
const endTime = useDateFormat(props.tradeshow.end_date, 'h:mm a')

const dateCreated = useDateFormat(props.tradeshow.date_created, 'MMMM D, YYYY @ h:mm a')
const dateUpdated = props.tradeshow.date_updated ? useDateFormat(props.tradeshow.date_updated, 'MMMM D, YYYY @ h:mm a') : undefined

const headline = computed(() => `ICS + Trexon coming to ${props.tradeshow.name} in ${props.tradeshow.city}${props.tradeshow.booth ? `: find us at booth ${props.tradeshow.booth}` : ''}`)

const to = computed(() => `/about/tradeshows/${props.tradeshow.slug}`)
</script>

<template>
  <slot
    :to="to"
    :headline="headline"
    :start-date="startDate"
    :start-time="startTime"
    :end-date="endDate"
    :end-time="endTime"
    :date-created="dateCreated"
    :date-updated="dateUpdated"
  />
</template>
